<template>
  <screen_width_cont>
    <div class="myBodyGeneral">
      <div class="myHeader" ref="myHeader_regisFormInicial">
        <!-- <div class="cont_300N"> -->
        <div class="cont_300_basico ancho_acotado">
          <div class="header_top" v-if="nivelActual == 1">
            <div class="header_top">
              <headerCerrar @cancelar="callAbandonar" colorIcon="rojoOscuro" />
            </div>
          </div>
          <div class="header_top" v-else>
            <div class="header_top">
              <headerBack @back="backLocal" />
            </div>
          </div>
          <div class="regisFormInicialTitle title">
            {{ t("app.registroFormInicial.tit") }}
          </div>
        </div>
      </div>

      <!-- <div class="blur_sup" :style="blur_sup_regisFormInicial"></div> -->
      <blurComp
        class="blur_sup"
        :style="blur_sup_regisFormInicial"
        :scroll_to_="scroll_to_top"
        tipo="sup"
      />

      <div
        class="contenido centrado"
        :style="contenido_regisFormInicial"
        v-show="cont_visible"
        ref="contenido_regisFormInicial"
        @scroll="onScroll"
      >
        <!-- <div class="cont_300N"> -->
        <div class="cont_300_basico ancho_acotado">
          <div
            class=""
            v-if="listaElementosDomOrdenada.length > 0"
            ref="body_regisFormInicial"
          >
            <div
              v-for="(elemento, index) in listaElementosDomOrdenada"
              :key="index"
              :ref="elemento.variable"
            >
              <div
                class="regisFormInicialInputCont"
                v-if="
                  (elemento.tipo == 'text' || elemento.tipo == 'number') &&
                  !elemento.big
                "
              >
                <inputBoxComp
                  :typeInput="elemento.tipo"
                  :error="elemento.tieneError"
                  @llamarCambioElementoInput="cambioElementoLocal"
                  :mensajeError="[
                    t('configuracion.data.textos_errores.campoObligatorio'),
                    t('configuracion.data.textos_errores.rut'),
                  ]"
                  :mensajeOk="{ mostrar: false, texto: '' }"
                  :elementosDom="elemento"
                  :indiceElementoDom="index"
                />
              </div>

              <div
                class="regisFormEditInputCont"
                v-if="elemento.tipo == 'tel' && !elemento.big"
              >
                <input_telefonico
                  :typeInput="elemento.tipo"
                  :error="elemento.tieneError"
                  @llamarCambioElementoInput="cambioElementoLocal"
                  :mensajeError="[
                    t('configuracion.data.textos_errores.campoObligatorio'),
                    t('configuracion.data.textos_errores.rut'),
                  ]"
                  :mensajeOk="{ mostrar: false, texto: '' }"
                  :elementosDom="elemento"
                  :indiceElementoDom="index"
                />
              </div>

              <div
                class="regisFormEditInputCont"
                v-if="
                  (elemento.tipo == 'text' ||
                    elemento.tipo == 'tel' ||
                    elemento.tipo == 'number') &&
                  elemento.big
                "
              >
                <inputBoxComp_big
                  :typeInput="elemento.tipo"
                  :error="elemento.tieneError"
                  @llamarCambioElementoInput="cambioElementoLocal"
                  :mensajeError="[
                    t('configuracion.data.textos_errores.campoObligatorio'),
                    t('configuracion.data.textos_errores.rut'),
                  ]"
                  :mensajeOk="{ mostrar: false, texto: '' }"
                  :elementosDom="elemento"
                  :indiceElementoDom="index"
                  @call_explicacion="call_explicacion"
                />
              </div>

              <div
                class="regisFormInicialInputCont"
                v-if="
                  elemento.tipo == 'selectDir' &&
                  elemento.elementos &&
                  elemento.elementos.length > 0
                "
              >
                <div v-for="(controlSelect, i) in elemento.elementos" :key="i">
                  <selectBox3
                    :elementosDom="controlSelect"
                    @llamarPoblarOpcionesDir="poblarOpcionesElementoDirLocal"
                    :indiceElementoDom="index"
                    :mostrarLabel="i % 2 == 0"
                    :mensajeError="
                      t('configuracion.data.textos_errores.campoObligatorio')
                    "
                    :disabled="false"
                    tabindex="0"
                    :error="controlSelect.tieneError"
                  />
                </div>
              </div>

              <!-- ***** -->
              <div
                class="regisFormEditInputCont"
                v-if="
                  elemento.tipo == 'select' && elemento.elementos.length > 0
                "
              >
                <selectBasicoDinamicRemit
                  :elementosDom="elemento"
                  @llamarCambioElemento="cambioElementoLocal"
                  :indiceElementoDom="index"
                  :mostrarLabel="true"
                  :mensajeError="
                    t('configuracion.data.textos_errores.campoObligatorio')
                  "
                  :disabled="false"
                  tabindex="0"
                  :error="elemento.tieneError"
                  :registrando="true"
                />
              </div>
              <!-- **** -->

              <div
                class="regisFormInicialInputCont"
                v-if="elemento.tipo == 'radioBtns'"
              >
                <hr class="regisFormInicialHr" />

                <radioBtnsAzulComp
                  :elementosDom="elemento"
                  :indiceElementoDom="index"
                  @llamarCambioElemento="cambioElementoRadioBtnsLocal"
                  @call_explicacion="call_explicacion"
                />
              </div>
            </div>
          </div>

          <div class="regisFormInicialActionBtn">
            <btnAction
              :textBtn="
                nivelActual < niveles
                  ? t('app.comunes.btns.continuar')
                  : t('app.comunes.btns.registrarse')
              "
              :disabled="!btnActive"
              @action="continuarRegistro"
              colorBack="rojo"
              colorText="blco"
              :estadoBtn="estadoBtn"
              :msg_completar="t('app.comunes.btns.completar_formulario')"
            />
          </div>
        </div>
      </div>

      <!-- <div class="blur_inf" :style="blur_inf_regisFormInicial"></div> -->
      <blurComp
        class="blur_inf"
        :style="blur_inf_regisFormInicial"
        :scroll_to_="scroll_to_bottom"
        tipo="inf"
      />

      <div class="footer" ref="footer_regisFormInicial">
        <div class="">
          <pasosGiro :paso="pasoActualEtapas" totales="4" />
        </div>
        <div class="footer_env_seguro">
          <enviosSeguros @callEnvioSeguroModal_="callEnvioSeguroModal" />
        </div>
      </div>
    </div>
  </screen_width_cont>
</template>

<script>
import { modalController, alertController } from "@ionic/vue";
import { mapActions, mapMutations, mapState } from "vuex";
import { useI18n } from "vue-i18n";
import headerBack from "../components/generales/headerBack.vue";
import inputBoxComp from "../components/generales/inputBoxDinamicComp.vue";
import inputBoxComp_big from "../components/remitente/inputBoxDinCompRem_big.vue";
import btnAction from "../components/generales/btnAction.vue";
import selectBox3 from "../components/remitente/selectBoxDinCompRem.vue";
import radioBtnsAzulComp from "../components/remitente/radioBtnsAzulCompRem.vue";
import pasosGiro from "../components/generales/pasosProceso.vue";
import enviosSeguros from "../components/generales/envios_seguros.vue";
import envioSeguroHome from "./modales/enviosSegurosHome.vue";
import blurComp from "../components/generales/blur.vue";
import {
  blur_sup_scroll,
  blur_inf_scroll,
  contenido_style,
  blur_sup_style,
  blur_inf_style,
} from "../assets/funciones/blur";
import explicacion from "./modales/explicacion.vue";
import selectBasicoDinamicRemit from "../components/remitente/selectBasicoDinamicRemit.vue";
import screen_width_cont from "../components/menuSp/screen_width_cont.vue";
import estas_seguro from "./modales/estas_seguro.vue";
import headerCerrar from "../components/generales/headerCerrar.vue";
import input_telefonico from "../components/remitente/input_telefonico.vue";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  name: "registroFormInicial",
  components: {
    headerBack,
    inputBoxComp,
    inputBoxComp_big,
    btnAction,
    selectBox3,
    radioBtnsAzulComp,
    pasosGiro,
    enviosSeguros,
    blurComp,
    screen_width_cont,
    selectBasicoDinamicRemit,
    headerCerrar,
    input_telefonico,
  },
  data() {
    return {
      editarDatos: true,
      niveles: 0,
      nivelActual: 0,
      enVista: true,
      countBack: 1,
      soyPep: null,
      btnActive: true,
      header: 0,
      footer: 0,
      cont_visible: false,
      obj_valida_basico: {},
      scroll_to_bottom: true,
      scroll_to_top: true,
      contenido_regisFormInicial: {},
      blur_sup_regisFormInicial: {},
      blur_inf_regisFormInicial: {},
      variable_con_error: "",
      estado_validaciones: false,
    };
  },
  computed: {
    ...mapState([
      "infoNodos",
      "listaElementosDom",
      "remitente",
      "flujo",
      "configuracion",
      "respRemitenteModif",
      "listaElementosDomTemp",
      "procesoGeneral",
      "heigthScreen",
      "widthScreen",
      "plataforma",
      "remitenteTemp",
      "datos_consultados",
      "url_base_web",
      "idiomaPreferencia",
    ]),

    listaElementosDomOrdenada() {
      let aux = [];
      let proceso = "";
      if (this.procesoGeneral == "registro") proceso = "reg";
      if (this.procesoGeneral == "logIn") proceso = "reg";
      if (this.procesoGeneral == "misDatos") proceso = "edit";
      if (this.listaElementosDom.length > 0) {
        this.configuracion.reglasFormularioRemitente.forEach(async (item) => {
          if (
            proceso != "" &&
            item[15][proceso] &&
            item[15][proceso][0] == "si"
          ) {
            this.listaElementosDom.forEach((elemento) => {
              if (elemento.variable == item[4]) {
                // console.log("111111111", elemento.variable, "remitente", this.remitente)
                if (elemento.tipo == "selectDir") {
                  if (item[4] == "nacionalidad") {
                    if (this.remitente.nacionalidad != "152") {
                      elemento.elementos.forEach((element) => {
                        element.editable = item[15][proceso][1];
                      });
                    } else {
                      elemento.elementos.forEach((element) => {
                        element.editable = "no";
                      });
                    }
                  } else {
                    elemento.elementos.forEach((element) => {
                      element.editable = item[15][proceso][1];
                    });
                  }
                } else {
                  if (
                    elemento.variable == "direccion" &&
                    item[15].big &&
                    item[15].big[0] == "si"
                  ) {
                    elemento.editable = item[15][proceso][1];
                    elemento.big = true;
                  } else if (
                    item[4] == "nombre1" &&
                    (!this.remitente.nombre1 || this.remitente.nombre1 == "")
                  ) {
                    elemento.editable = "si";
                  } else if (
                    item[4] == "nombre2" &&
                    (!this.remitente.nombre2 || this.remitente.nombre2 == "")
                  ) {
                    elemento.editable = "si";
                  }  else if (
                    item[4] == "apellido1" &&
                    (!this.remitente.apellido1 ||
                      this.remitente.apellido1 == "")
                  ) {
                    elemento.editable = "si";
                  } else if (
                    item[4] == "apellido2" &&
                    (!this.remitente.apellido2 ||
                      this.remitente.apellido2 == "")
                  ) {
                    elemento.editable = "si";
                  } else {
                    elemento.editable = item[15][proceso][1];
                  }
                }
                aux.push(elemento);
              }
            });
          }
        });
      }

      // console.log(aux, this.remitente);
      return aux;
    },
    pasoActualEtapas() {
      let aux = 0;
      if (this.niveles == 2) {
        if (this.nivelActual == 1) {
          aux = 3;
        } else if (this.nivelActual == 2) {
          aux = 4;
        }
      }
      return aux;
    },

    estadoBtn() {
      let aux = "inactive";
      if (this.estado_validaciones) {
        aux = "active";
      }
      return aux;
    },
  },
  methods: {
    ...mapMutations([
      "updateDesde",
      "updateListaElementosDom",
      "updateInfoNodos",
      "updateBloquearNavegacion",
      "setRemitenteTemp",
      "dropRemitenteTemp",
      "updateListaElementosDomSpPropiedad",
      "updateListaElementosDomSpPropiedadDirTodo",
      "updateListaElementosDomSpPropiedadDirCero",
      "limpiarRemitente",
      "setshowSpinner",
    ]),
    ...mapActions([
      "dibujarNodosNivel",
      "crearNodoInput",
      "cambioElementoVue",
      "poblarOpcionesElementoDir",
      "tieneErroresNivelDetalle",
      "remitenteUpdate",
      "cambioElementoCheckBox",
      "cambioElementoRadioBtns",
      "signOff",
      "traer_comunas_v2",
    ]),

    async verificar_estado_validaciones() {
      let tieneErroresNivelX = await this.tieneErroresNivelDetalle({
        tipo_persona: "Remitente",
        id_persona: "0",
        nivel: this.nivelActual,
        edit_destinatario: this.edit_destinatario,
      });

      let aux = !tieneErroresNivelX.resultado;
      this.estado_validaciones = aux;
    },

    async poblarOpcionesElementoDirLocal(data) {
      let cambioData = await this.poblarOpcionesElementoDir({
        tipo_persona: data.tipo_persona,
        variable: data.variable,
        valor: data.valor,
      });
      this.verificar_estado_validaciones();
      if (cambioData) {
        if (this.listaElementosDom[data.indiceElementoDom]) {
          this.updateListaElementosDomSpPropiedadDirTodo({
            index: data.indiceElementoDom,
            valor: cambioData.elementos,
          });
        }
      }
    },
    async cambioElementoLocal(data) {
      let nombreVariable = data.elemento.split("-")[3];

      if (nombreVariable == "region") {
        let dato_existe = false;
        if (this.datos_consultados["152"]) {
          if (this.datos_consultados["152"].comuna_id) {
            if (this.datos_consultados["152"].comuna_id[`${data.valor}`]) {
              if (
                this.datos_consultados["152"].comuna_id[`${data.valor}`].status
              ) {
                dato_existe = true;
              }
            }
          }
        }
        // console.log(dato_existe, this.datos_consultados);
        if (!dato_existe) {
          this.setshowSpinner(true);
          let resp = await this.traer_comunas_v2({
            pais: "152",
            region: data.valor,
          });
          if (resp) {
            this.setshowSpinner(false);
          } else {
            this.setshowSpinner(false);
            //mostrar aviso de error / opción de reintentar
          }
        }
      }

      let cambioData = await this.cambioElementoVue({
        elemento: data.elemento,
        valor: data.valor,
        accion: data.accion,
        tipo_persona: data.tipo_persona,
        clasetienedependiente: data.clasetienedependiente,
        borrarValorHijo: true,
      });
      this.verificar_estado_validaciones();
      let elemento = data.elemento.split("-");
      if (cambioData.cargarDataAlElementoDom) {
        let index = this.listaElementosDom.findIndex((item) => {
          return (
            `slct-${item.nombre_elemento}` ==
            cambioData.dataNuevaPasada.elemento
          );
        });

        if (index > -1) {
          this.updateListaElementosDomSpPropiedad({
            index: index,
            propiedad: "elementos",
            valor: cambioData.elementos.elementos,
          });
          this.updateListaElementosDomSpPropiedad({
            index: index,
            propiedad: "isFilledTag",
            valor: cambioData.isFilledTag,
          });
          this.updateListaElementosDomSpPropiedad({
            index: index,
            propiedad: "tieneError",
            valor: cambioData.tieneError,
          });

          this.updateListaElementosDomSpPropiedad({
            index: index,
            propiedad: "fireChange",
            valor: true,
          });
          setTimeout(() => {
            this.updateListaElementosDomSpPropiedad({
              index: index,
              propiedad: "fireChange",
              valor: false,
            });
          }, 500);
        }
      } else {
        let index = this.listaElementosDom.findIndex((item) => {
          return item.variable == elemento[3];
        });

        if (index > -1 && this.listaElementosDom[index]) {
          this.updateListaElementosDomSpPropiedad({
            index: index,
            propiedad: "tieneError",
            valor: cambioData.tieneError,
          });
        }
      }
    },

    async cambioElementoRadioBtnsLocal(data) {
      let evaluacion = await this.cambioElementoRadioBtns(data);
      this.verificar_estado_validaciones();
      let index = this.listaElementosDom.findIndex((item) => {
        return item.variable == data.variable;
      });

      if (index > -1 && this.listaElementosDom[data.indiceElementoDom]) {
        this.updateListaElementosDomSpPropiedad({
          index: index,
          propiedad: "tieneError",
          valor: evaluacion.tieneError,
        });
      }
    },

    cargarElementosDom() {
      this.updateListaElementosDom([]);
      this.dibujarNodosNivel({
        tipo_persona: "Remitente",
        id_persona: this.remitente.id,
        nivel: this.nivelActual,
      });
    },
    async continuarRegistro() {
      let tieneErroresNivelX = await this.tieneErroresNivelDetalle({
        tipo_persona: "Remitente",
        id_persona: 0,
        nivel: this.nivelActual,
      });

      if (!tieneErroresNivelX.resultado) {
        if (this.nivelActual + 1 <= this.niveles) {
          this.nivelActual += 1;
          this.cargarElementosDom();
          this.setshowSpinner(false);
          setTimeout(() => {
            this.$refs["contenido_regisFormInicial"].scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }, 100);
        } else {
          this.remitenteUpdate();
        }
      } else {
        this.setshowSpinner(false);
        this.variable_con_error = "";
        tieneErroresNivelX.detalle.forEach((error) => {
          let aux = this.listaElementosDom.findIndex((elem) => {
            return elem.variable == error[1] && error[0] < 2;
          });
          if (aux > -1) {
            if (this.listaElementosDom[aux].tipo == "selectDir") {
              this.updateListaElementosDomSpPropiedadDirCero({
                index: aux,
                propiedad: "tieneError",
                valor: true,
              });
            }

            this.updateListaElementosDomSpPropiedad({
              index: aux,
              propiedad: "tieneError",
              valor: true,
            });

            if (this.variable_con_error == "") {
              this.scroll_to_error(error[1]);
              this.variable_con_error = error[1];
            }
          }
        });
      }
    },
    scroll_to_error(variable_con_error) {
      this.$refs["contenido_regisFormInicial"].scrollTo({
        top: this.$refs[variable_con_error][0].offsetTop + 15,
        behavior: "smooth",
      });
    },
    backLocal() {
      if (this.nivelActual == 1) {
        this.$router.push({ name: "logIn", replace: true });
      } else {
        this.nivelActual -= 1;
        this.cargarElementosDom();

        setTimeout(() => {
          this.$refs["body_regisFormInicial"].scrollIntoView({
            block: "end",
            scrollBehavior: "smooth",
          });
        }, 100);
      }
    },
    async callAbandonar() {
      this.updateBloquearNavegacion(true);
      const modal = await modalController.create({
        component: estas_seguro,
        componentProps: {
          title: this.t("app.recoverPassForm.tit_abandonar"),
          color_title: "rojo",
          color_btn_1: "rojo",
          color_btn_2: "gris",
          text: "",
          btnText_1: this.t("app.comunes.btns.salir"),
          btnText_2: "",
          colorIcon: "rojoOscuro",
        },
        cssClass:
          this.widthScreen < 600
            ? [
                "myModalAbandonar",
                "myModalAbandonar_ancho_80",
                "myModalAbandonar_alto_50",
              ]
            : [
                "myModalAbandonar",
                "myModalAbandonar_ancho_40",
                "myModalAbandonar_alto_40",
              ],
        // showBackdrop: false,
        animated: true,
        backdropDismiss: false,
        // swipeToClose: true,
        // mode: "ios",
      });

      modal.onDidDismiss().then((data) => {
        this.updateBloquearNavegacion(false);
        if (
          data &&
          data.data &&
          data.data.action &&
          data.data.action == "action_1"
        ) {
          if (this.plataforma == "web") {
            window.location.replace(
              `${this.url_base_web}/${this.idiomaPreferencia.cod}/`
            );
          } else {
            this.$router.push({ name: "logIn", replace: true });
          }
        } else if (
          data &&
          data.data &&
          data.data.action &&
          data.data.action == "action_2"
        ) {
          // this.$router.push({ name: "nuevoDestinatarioForm" });
        }
      });
      return modal.present();
    },

    async callEnvioSeguroModal() {
      this.updateBloquearNavegacion(true);
      const modal = await modalController.create({
        component: envioSeguroHome,
        // cssClass: 'my-custom-class',
        // componentProps: {
        //   dataTransfer: data
        // },
      });
      modal.onDidDismiss().then(() => {
        this.updateBloquearNavegacion(false);
      });
      return modal.present();
    },
    async call_explicacion(tipo) {
      if (tipo == "direccion" && this.remitenteTemp.residencia == "152") return;
      let cssClass = ["myModalAbandonar"];
      if (tipo == "cargo_jerarquico") {
        if (this.widthScreen < 400) {
          cssClass.push("myModalAbandonar_ancho_90");
        } else if (this.widthScreen < 600) {
          cssClass.push("myModalAbandonar_ancho_80");
        } else if (this.widthScreen < 800) {
          cssClass.push("myModalAbandonar_ancho_70");
        } else {
          cssClass.push("myModalAbandonar_ancho_50");
        }

        if (this.heigthScreen < 700) {
          cssClass.push("myModalAbandonar_alto_90");
        } else if (this.heigthScreen < 900) {
          if (this.widthScreen < 400) {
            cssClass.push("myModalAbandonar_alto_90");
          } else {
            cssClass.push("myModalAbandonar_alto_70");
          }
        } else if (this.heigthScreen < 1200) {
          cssClass.push("myModalAbandonar_alto_60");
        } else {
          cssClass.push("myModalAbandonar_alto_60");
        }
      } else if (tipo == "direccion") {
        if (this.widthScreen < 400) {
          cssClass.push("myModalAbandonar_ancho_70");
        } else if (this.widthScreen < 600) {
          cssClass.push("myModalAbandonar_ancho_50");
        } else if (this.widthScreen < 800) {
          cssClass.push("myModalAbandonar_ancho_40");
        } else {
          cssClass.push("myModalAbandonar_ancho_40");
        }

        if (this.heigthScreen < 800) {
          cssClass.push("myModalAbandonar_alto_50");
        } else if (this.heigthScreen < 900) {
          cssClass.push("myModalAbandonar_alto_40");
        } else if (this.heigthScreen < 1200) {
          cssClass.push("myModalAbandonar_alto_40");
        } else {
          cssClass.push("myModalAbandonar_alto_40");
        }
      }

      let titulo = "";
      let texto = "";
      if (tipo == "cargo_jerarquico") {
        titulo = this.t("app.comunes.cargo_jerarquico");
        texto = this.t("app.registroFormInicial.pep_descripcion");
      } else if (tipo == "direccion") {
        titulo = this.t("app.comunes.direccion");
        texto = this.t("app.registroFormInicial.direccion");
      }
      this.updateBloquearNavegacion(true);
      const modal = await modalController.create({
        component: explicacion,
        componentProps: {
          title: titulo,
          color_title: "azul",
          color_btn_1: "azul",
          color_btn_2: "gris",
          text: texto,
          btnText_1: this.t("app.comunes.btns.entiendo"),
          btnText_2: "",
          colorIcon: "rojoOscuro",
        },
        cssClass: cssClass,
        // showBackdrop: false,
        animated: true,
        backdropDismiss: false,
      });
      modal.onDidDismiss().then(() => {
        this.updateBloquearNavegacion(false);
      });
      return modal.present();
    },
    valida_basico(data) {
      this.obj_valida_basico[data.variable] = data.valida;
      this.verificar_estado_validaciones();
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      this.scroll_to_top = blur_sup_scroll(scrollTop);
      this.scroll_to_bottom = blur_inf_scroll(
        scrollTop,
        clientHeight,
        scrollHeight
      );
    },
    async presentAlert(data) {
      let alert = await alertController.create({
        header: data.title,
        subHeader: data.msg,
        buttons: ["Ok"],
        backdropDismiss: false,
      });
      await alert.present();
    },
  },
  watch: {
    async infoNodos() {
      if (this.infoNodos.length > 0 && this.enVista) {
        // console.log(this.infoNodos);
        for (const element of this.infoNodos) {
          await this.crearNodoInput({
            elementoDom: element.elementoDom,
            tipo_persona: element.tipo_persona,
            id_persona: element.id_persona,
            variable: element.variable,
            pais: element.pais,
          });
        }
        this.updateListaElementosDom(this.listaElementosDomTemp);
        // setTimeout(() => {
        //   this.updateListaElementosDom(this.listaElementosDomTemp);
        // }, 1000);

        this.contenido_regisFormInicial = contenido_style(
          this.heigthScreen,
          this.header,
          this.footer,
          this.plataforma == "web" ? 63 : 0
        );
      }
    },
    async respRemitenteModif() {
      if (this.respRemitenteModif.status) {
        this.setshowSpinner(false);
        if (this.respRemitenteModif.resp) {
          this.$router.push({ name: "home", replace: true });
        } else {
          console.log(
            "Error al registrar cambios: ",
            this.respRemitenteModif.msg
          );
          this.presentAlert({
            title: "Error",
            msg: this.t(
              `configuracion.errores_srv.Cliente.${this.respRemitenteModif.msg}`
            ),
          });
        }
      }
    },
    heigthScreen() {
      this.contenido_regisFormInicial = contenido_style(
        this.heigthScreen,
        this.header,
        this.footer,
        this.plataforma == "web" ? 63 : 0
      );
    },
  },
  created() {
    this.updateListaElementosDom([]);
    this.updateInfoNodos([]);
    this.niveles = Math.max.apply(
      Math,
      this.configuracion.reglasFormularioRemitente.map((campo) => {
        return campo[3];
      })
    );
    this.nivelActual = 1;
  },
  mounted() {},
  async ionViewWillEnter() {
    this.enVista = true;
    this.updateDesde("registroFormulario");
    this.updateListaElementosDom([]);
    this.updateInfoNodos([]);
    this.setRemitenteTemp();
    this.cargarElementosDom();
  },
  ionViewDidEnter() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.header = this.$refs["myHeader_regisFormInicial"].offsetHeight;
        this.footer = this.$refs["footer_regisFormInicial"].offsetHeight;
        this.cont_visible = true;
        this.contenido_regisFormInicial = contenido_style(
          this.heigthScreen,
          this.header,
          this.footer,
          this.plataforma == "web" ? 63 : 0
        );
        this.blur_sup_regisFormInicial = blur_sup_style(this.header);
        this.blur_inf_regisFormInicial = blur_inf_style(this.footer);
      }, 100);

      setTimeout(() => {
        if (
          this.$refs["contenido_regisFormInicial"].scrollHeight >
          this.$refs["contenido_regisFormInicial"].clientHeight
        ) {
          this.scroll_to_bottom = false;
        }
      }, 100);
    });

    // this.header = this.$refs[""].offsetHeight;
    // this.footer = this.$refs[""].offsetHeight;
    // setTimeout(() => {
    //   this.header = this.$refs["myHeader_regisFormInicial"].offsetHeight;
    //   this.footer = this.$refs["footer_regisFormInicial"].offsetHeight;
    //   this.cont_visible = true;
    // }, 100);
  },
  ionViewWillLeave() {
    this.setshowSpinner(false);
    this.cont_visible = false;
    this.enVista = false;
    this.dropRemitenteTemp();
    // console.log("$$$$$$$", this.respRemitenteModif);
    if (!this.respRemitenteModif.status || !this.respRemitenteModif.resp) {
      this.limpiarRemitente({});
      this.signOff();
    }
  },
};
</script>

<style>
.regisFormInicialTitle {
  margin-top: 1rem;
  padding-bottom: 0.3rem;
  color: var(--customA-801B1C);
  text-align: center;
}

.regisFormInicialInputCont {
  margin-top: 0.8rem;
}

.regisFormInicialHr {
  margin-top: 0.45rem;
  margin-bottom: 1.25rem;
}

.regisFormInicialActionBtn {
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  margin: 4rem 0 0 0;
}
</style>