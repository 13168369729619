<template>
  <div>
    <div class="entradaLabelCont">
      <span v-show="variable != ''">{{
        t(
          `variables.reglasFormulario['${prefijoI18n}${elementosDom.variable}']`
        )
      }}</span>
    </div>
    <div
      class="caja_entrada"
      :class="
        stateInput == 'noEditable'
          ? 'noEditable'
          : stateInput == 'inicio'
          ? 'inicio'
          : stateInput == 'onFocus'
          ? 'onFocus'
          : stateInput == 'post'
          ? 'post'
          : stateInput == 'onError'
          ? 'onError'
          : ''
      "
    >
      
      <input
        :type="elementosDom.tipo"
        class="inputDinamicInputBox caja_entrada_text"
        :class="stateInput == 'onError' ? 'error' : ''"
        :placeholder="`${t(
          `variables.reglasFormulario['${prefijoI18n}${elementosDom.variable}']`
        )}*`"
        v-model="variable"
        @keypress="isRut($event)"
        autocomplete="off"
        @focus="cambiarType($event)"
        @blur="cambiarType($event)"
        :ref="elementosDom.variable == 'numero_documento' ? 'rut' : ''"
        :disabled="stateInput == 'noEditable'"
        @keyup="call_valida_basico()"
      />
    </div>
    <div class="mensajesForm">
      <div class="mensajeError">
        <span v-show="stateInput == 'onError'">{{
          variable == "" ? mensajeError[0] : mensajeError[1]
        }}</span>
      </div>
      <div
        :class="stateInput == 'onFocus' ? 'mensajeOkFocus' : 'mensajeOkBlur'"
        v-show="mensajeOk.mostrar"
      >
        {{ mensajeOk.texto }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  props: {
    typeInput: { type: String },
    mensajeError: { type: Array },
    mensajeOk: { type: Object },
    error: { type: Boolean },
    elementosDom: { type: Object },
    indiceElementoDom: { type: Number },
  },
  data() {
    return {
      variable: "",
      stateInput: "inicio",
      ojo: require("../../assets/imgs/ojo.svg"),
      ojoCerrado: require("../../assets/imgs/ojoCerrado.svg"),
      interactuado: false,
      textoPassVisible: false,
      inFocus: false,
      prefijoI18n: "",
    };
  },
  methods: {
    ...mapActions(["validarRut"]),

    isRut(evt) {
      if (this.elementosDom.variable == "numero_documento") {
        evt = evt ? evt : window.event;
        let caracter = String.fromCharCode(evt.which);
        let search = /[0-9kK-]/;
        if (caracter.match(search)) {
          return true;
        } else {
          evt.preventDefault();
        }
        if (this.variable == "-") {
          this.variable = "";
        }
      }
    },

    async cambiarType(evt) {
      let evento = evt.type;

      if (!this.textoPassVisible) {
        
        if (this.elementosDom.variable == "numero_documento") {
          
          if (evento == "blur") {
            this.variable = this.variable.replaceAll(".", "");
            this.variable = this.variable.replaceAll("-", "");
            let numChar = this.variable.length;
            let rut = this.variable.substring(0, numChar - 1);
            let digito = this.variable.substring(numChar - 1);
            this.variable = `${rut}-${digito}`;
            if (this.variable == "-") {
              this.variable = "";
            }
          }
        }
        
      }

      if (evento == "focus") {
        this.stateInput = "onFocus";
        this.inFocus = true;
      } else if (evento == "blur") {
        this.inFocus = false;
        
        if (this.error) {
          this.stateInput = "onError";
        } else {
          this.stateInput = "post";
        }


        let data = {
          elemento: this.elementosDom.identificador,
          accion: "noImporta",
          tipo_persona: this.elementosDom.tipo_persona,
        };

        data.indiceElementoDom = this.indiceElementoDom;
        data.clasetienedependiente = "";
        data.valor = this.variable;
        await this.$emit("llamarCambioElementoInput", data);
      }

      this.interactuado = true;
    },

    setPrefijoI18n() {
      if (this.elementosDom.tipo_persona == "Remitente") {
        this.prefijoI18n = "Remitente_";
      } else if (this.elementosDom.tipo_persona == "Destinatario") {
        this.prefijoI18n = "Destinatario_";
      }
    },
    call_valida_basico() {
      this.$emit("valida_basico_", {
        variable: this.elementosDom.identificador,
        valida:
          this.variable != ""
            ? true
            : this.elementosDom.variable == "apellido2"
            ? true
            : false,
      });
    },
  },
  watch: {
    error() {
      
      if (this.error) {
        this.stateInput = "onError";
      } else {
        if (this.inFocus) {
          this.stateInput = "onFocus";
        } else {
          this.stateInput = "post";
        }
      }
      // }
    },
  },
  created() {},
  mounted() {
    // console.log("222222222", this.elementosDom);
    this.setPrefijoI18n();

    if (this.elementosDom.tipo == "number") {
      this.variable = parseInt(this.elementosDom.valor);
    } else {
      this.variable = this.elementosDom.valor;
    }
    if (this.elementosDom.editable == "no") this.stateInput = "noEditable";

    this.call_valida_basico();

    if (this.elementosDom.variable == "apellido2") {
      let data = {
        elemento: this.elementosDom.identificador,
        accion: "noImporta",
        tipo_persona: this.elementosDom.tipo_persona,
      };

      data.indiceElementoDom = this.indiceElementoDom;
      data.clasetienedependiente = "";
      data.valor = this.variable;
      this.$emit("llamarCambioElementoInput", data);
    }
  },
};
</script>

<style >
/* .inputBoxCompLabelCont {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 0.5rem;
  line-height: 9px;
  color: var(--customG-787878);
  padding-left: 0.3rem;
  min-height: 9px;
} */

.ojo {
  width: 1rem;
  display: flex;
  justify-content: center;
}
</style>